import React, {FC, useState} from 'react';
import ViewTitle from '../ViewTitle/ViewTitle';
import {Grid} from '@material-ui/core';
import {ReportDownloadButton} from '../ReportDownloadButton/ReportDownloadButton';
import {TextField} from "../TextField/TextField";
import Typography from "@material-ui/core/Typography";

export const ReportsPage: FC =
    () => {
        const [filter, setFilter] = useState('');
        const financialForecastUrl = getModelReportUrl(ReportType.FINANCIAL_FORECAST);
        const billingForecastUrl = getModelReportUrl(ReportType.BILLING_FORECAST);
        const costForecastUrl = getModelReportUrl(ReportType.COST_FORECAST);
        const modelAndConfigurationUrl = getModelReportUrl(ReportType.MODEL_AND_CONFIGURATION);
        const pricingUrl = getModelReportUrl(ReportType.PRICING_CONFIGURATION);
        const guaranteesUrl = getModelReportUrl(ReportType.GUARANTEES);
        const scopeConfigurationUrl = getModelReportUrl(ReportType.SCOPE_CONFIGURATION);
        const [isModalOpen, setIsModalOpen] = React.useState(false);
        const handleClick = () => setIsModalOpen(prevState => !prevState);
        
        return (
            <>
                <ViewTitle title='Reports'/>
                <div className='right-content-wrap'>
                    <Grid container spacing={8}>
                        <Grid item xs={6} lg={4}>
                            {/* tslint:disable-next-line:max-line-length */}
                            <Typography component="div" style={{ padding: '0 10px 0 10px' }}>The field below allows to enter opportunity number, contract name or any other value to filter data inside the report. You will receive the report as usual in CSV format, with all the lines that literally contain the value you entered in this field.</Typography>
                            {/* tslint:disable-next-line:max-line-length */}
                            <Typography component="div" style={{ padding: '10px 10px 0 10px' }}>This functionality allows you to filter data only from the existing reports. It does not generate any new reports.</Typography>
                        </Grid>
                        <Grid item xs={8}>&nbsp;</Grid>
                        <Grid item xs={6} lg={4} style={{ padding: '0 15px 20px 15px' }}>
                            <TextField
                                name='filter'
                                label='Filter'
                                value={filter}
                                onChange={event => setFilter(event.target.value)}
                                margin='dense'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={8}>&nbsp;</Grid>
                        <Grid item xs={6} lg={4}>
                            <ReportDownloadButton
                                href={financialForecastUrl}
                                testName='downloadFinancialForecastReport'
                                filter={filter}
                            >
                                Financial Forecast
                            </ReportDownloadButton>
                        </Grid>
                        <Grid item xs={8}>&nbsp;</Grid>

                        <Grid item xs={6} lg={4}>
                            <ReportDownloadButton
                                href={billingForecastUrl}
                                testName='downloadBillingForecastReport'
                                filter={filter}
                            >
                                Billing Forecast
                            </ReportDownloadButton>
                        </Grid>
                        <Grid item xs={8}>&nbsp;</Grid>

                        <Grid item xs={6} lg={4}>
                            <ReportDownloadButton
                                href={costForecastUrl}
                                testName='downloadCostForecastReport'
                                filter={filter}
                            >
                                Cost Forecast
                            </ReportDownloadButton>
                        </Grid>
                        <Grid item xs={8}>&nbsp;</Grid>

                        <Grid item xs={6} lg={4}>
                            <ReportDownloadButton
                                href={modelAndConfigurationUrl}
                                testName='downloadModelAndConfigurationReport'
                                filter={filter}
                            >
                                Model and Configuration
                            </ReportDownloadButton>
                        </Grid>
                        <Grid item xs={8}>&nbsp;</Grid>

                        <Grid item xs={6} lg={4}>
                            <ReportDownloadButton
                                href={guaranteesUrl}
                                testName='downloadGuaranteesReport'
                                filter={filter}
                            >
                                Guarantees
                            </ReportDownloadButton>
                        </Grid>
                        <Grid item xs={8}>&nbsp;</Grid>
                        
                        <Grid item xs={6} lg={4}>
                            <ReportDownloadButton
                                href={scopeConfigurationUrl}
                                testName='downloadScopeConfigurationReport'
                                filter={filter}
                            >
                                Scope Configuration
                            </ReportDownloadButton>
                        </Grid>
                        <Grid item xs={8}>&nbsp;</Grid>

                        <Grid item xs={6} lg={4}>
                            <ReportDownloadButton
                                 href={pricingUrl}
                                 testName='downloadPricingConfigurationReport'
                                 filter={filter}
                             >
                                 Pricing Configuration
                            </ReportDownloadButton>
                        </Grid>
                        <Grid item xs={8}>&nbsp;</Grid>
                    </Grid>
                </div>
             
            </>
        );
    };

function getModelReportUrl(type: ReportType) {
    return `${REPORT_ROOT}/reports?type=${type}`;
}

enum ReportType {
    FINANCIAL_FORECAST = 'FINANCIAL_FORECAST',
    BILLING_FORECAST = 'BILLING_FORECAST',
    COST_FORECAST = 'COST_FORECAST',
    MODEL_AND_CONFIGURATION = 'MODEL_AND_CONFIGURATION',
    PRICING_CONFIGURATION = 'PRICING_CONFIGURATION',
    GUARANTEES = "GUARANTEES",
    SCOPE_CONFIGURATION = 'SCOPE_CONFIGURATION',
}
