import React, {FC, ReactNode} from 'react';
import Button from '../Buttons/Button';
import {DownloadLink} from '../links/DownloadLink';
import {CircularProgress} from "@material-ui/core";
import {StyledComponentProps, StyleRules, Theme, withStyles} from '@material-ui/core/styles';

interface ReportDownloadButtonProps {
    href: string,
    children: ReactNode,
    testName: string,
    filter: string
}

const ReportDownloadButton0: FC<StyledComponentProps & ReportDownloadButtonProps> =
    ({ href, testName, children, classes , filter}) =>
        <DownloadLink href={getUrlWithFilter(href, filter)}>
            {(onClick, loading) =>
                <>
                    <Button
                        data-link={href}
                        testName={testName}
                        variant='contained'
                        color='primary'
                        onClick={onClick}
                        className={classes.button}
                    >
                        {children}
                    </Button>
                    {loading
                        ? <CircularProgress className={classes.loadingSpinner} size={20}/>
                        : null
                    }
                </>
            }
        </DownloadLink>
;

export const ReportDownloadButton = withStyles(getStyles)(ReportDownloadButton0);

function getStyles(theme: Theme): StyleRules {
    return {
        button: {
            marginRight: theme.spacing.unit * 2,
            width: '100%',
        },
        loadingSpinner: {
            verticalAlign: 'middle',
        },
    }
}

function getUrlWithFilter(href: string, filter: string): string {
    return !!filter ? `${href}&filter=${filter}` : href;
}